import React from "react";
import MobileMovieSlider from "../MobileMovieSlider";
import { Grid } from "@material-ui/core";
import GreyLine from "../GreyLine";
import "./style.scss";
import RevealAnimation from "../RevealAnimation";
function TechnologySection({
  hideMainTitle,
  mainTitleClass = "",
  sectionTitle,
  mainTitle,
  mobileSlider,
  slideId,
  listData,
  showDivider,
  showMoreClass = "",
}) {
  return (
    <div className="technology-section">
      <div className={`body-container`}>
        <div className={`technology-container ${mainTitleClass}`}>
          {!hideMainTitle && (
            <RevealAnimation component={"h3"} className="title-header ttc">
              {mainTitle}
            </RevealAnimation>
          )}

          <RevealAnimation
            animation="fade"
            component={"h3"}
            className="sub-header mt-50"
          >
            {sectionTitle}
          </RevealAnimation>
          <MobileMovieSlider movieSlider={mobileSlider} slideid={slideId} />
          <Grid
            container
            className="mt-20 mbl-hide"
            direction="row"
            spacing={1}
          >
            {listData.map((item, index) => (
              <Grid key={index} item sm={6} md={3}>
                <img alt={item.name} src={item.imgUrl} />
                <p style={{ fontSize: "16px" }}>{item.name}</p>
              </Grid>
            ))}
            <RevealAnimation
              animation="zoom-in"
              className={`more-wrap ${showMoreClass}`}
            >
              <p className="more-text">and more...</p>
            </RevealAnimation>
          </Grid>
        </div>
        {showDivider && <GreyLine />}
      </div>
    </div>
  );
}

export default TechnologySection;
