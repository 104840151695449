import React, { useState } from "react";
import Button from "../Button";
import RightArrow from "../../assets/Icons/go.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import { useContactModal } from "../Modal/contactModalContext";

function ReadMoreWithLeadForm({
  has_more,
  page,
  setPage,
  setLimit,
  moreClientBtn = true,
}) {
  const [isHover, setIsHover] = useState(false);
  const { setOpenModal } = useContactModal();
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  const handleMore = () => {
    setPage(page === 1 ? page + 2 : page + 1);
    setLimit(3);
  };
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <div
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        className="mt-60"
        role="presentation"
        style={{ display: "inline-block" }}
      >
        {moreClientBtn && (
          <Button
            icon={
              <img
                className="effect-btn-svgicon"
                src={!isHover ? RightArrow : RightArrowWhite}
                alt="More Client Stories"
              />
            }
            onClick={() => (has_more ? handleMore() : setOpenModal(true))}
            customClassName="info-view-resize-btn bg-white"
            color="primary"
            variant="outlined"
            label="More Client Stories"
          />
        )}
      </div>
    </div>
  );
}

export default ReadMoreWithLeadForm;
