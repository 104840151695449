import React from "react";
import "./style.scss";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import HeroImage from "../../assets/Images/Homepage/HeroSection/hero-illustration.png";
import { Link } from "@reach/router";
import image1 from "../../assets/Images/form/image1.png";
import image2 from "../../assets/Images/form/image2.png";
import image3 from "../../assets/Images/form/image3.png";
import FormSection from "../FormSection";
import RevealAnimation from "../RevealAnimation";

function HeroSection() {
  const listObject = [
    "Build New Product",
    "Enhance Existing Product",
    "24/7 Support",
  ];
  const imageList = [image1, image2, image3];
  return (
    <div className="lpHeroSection ">
      <div className="heroSectionWrap body-container">
        <div className="leftSectionWrapper">
          <RevealAnimation component={"h2"} className="title">
            We Turn Your Concept to Code: <br />
            Silicon Valley's Blueprint to <br />
            Success <span className="subTitle">| 200+ Success Stories</span>
          </RevealAnimation>
          <RevealAnimation animation="fade" className="list">
            {listObject.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.26825"
                    width="24"
                    height="24"
                    rx="12"
                    fill="#E89F38"
                  />
                  <path
                    d="M18 5.86823L17.8364 5.75312L17.8363 5.75327L17.8359 5.75391L17.834 5.75655L17.8265 5.76719L17.7965 5.80952C17.77 5.84695 17.7303 5.9026 17.6783 5.97528C17.5743 6.12065 17.4205 6.33415 17.2221 6.60627C16.8253 7.15052 16.2497 7.92922 15.5354 8.86637C14.1064 10.7409 12.1229 13.2483 9.90367 15.7811C9.37776 16.381 8.90051 16.6 8.49311 16.6261C8.08233 16.6524 7.70105 16.4853 7.36422 16.2294C7.02742 15.9736 6.75049 15.6402 6.55592 15.3658C6.45924 15.2295 6.38428 15.1097 6.33373 15.0244C6.30847 14.9818 6.28938 14.948 6.27678 14.9251L6.26281 14.8994L6.25956 14.8932L6.25889 14.8919L6.25886 14.8919C6.25884 14.8918 6.25882 14.8918 6.25881 14.8918L6.2588 14.8918C6.2192 14.8152 6.13499 14.7726 6.04984 14.7861C5.96465 14.7996 5.89771 14.8662 5.88377 14.9513C5.69619 16.0971 5.8337 16.9783 6.19672 17.6173C6.56149 18.2594 7.14102 18.634 7.78759 18.7867C9.06416 19.0883 10.625 18.5323 11.4303 17.46L11.4304 17.46C12.5341 15.9903 14.2229 13.1104 15.6303 10.6091C16.3354 9.3562 16.9719 8.19455 17.4321 7.34617C17.6622 6.92195 17.8483 6.576 17.9769 6.33607C18.0412 6.2161 18.0911 6.12263 18.125 6.05912L18.1635 5.98677L18.1733 5.96827L18.1758 5.96357L18.1764 5.96238L18.1766 5.96208C18.1766 5.96201 18.1767 5.96197 18 5.86823ZM18 5.86823L17.8365 5.75308C17.8973 5.66675 18.0147 5.64254 18.1047 5.69779C18.1946 5.75304 18.2262 5.86871 18.1767 5.96197L18 5.86823Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.4"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>{item}</p>
              </div>
            ))}
          </RevealAnimation>
          <div>
            <div className="card_container">
              <div className="card_wrapper_container">
                <RevealAnimation component={"h4"}>
                  Consult with our experts
                </RevealAnimation>
                <RevealAnimation animation="zoom-in" className="pipeline_image">
                  {imageList?.map((image, i) => {
                    return <img src={image} alt="" key={i} />;
                  })}
                </RevealAnimation>
                <RevealAnimation component={"p"} animation="flip-up">
                  A team member will reach out promptly for an initial discovery
                  call.
                </RevealAnimation>
              </div>
            </div>
          </div>
        </div>
        <RevealAnimation animation="zoom-in" className="rightSectionWrapper">
          {/* <img className={"hero-image"} src={HeroImage} alt={""} /> */}
          <FormSection onlyForm />
        </RevealAnimation>
      </div>
    </div>
  );
}

export default HeroSection;
